<template>
  <el-container>
    <el-main>
      <div class="main-content" v-if="!isResult" ref="mianBox">
        <el-row v-if="!isAdd&&existCompanyList.length>0&&isShowList">
          <p class="exist_title">已认证企业</p>
          <div
              :class="{'exist_list': true, 'checked': audit.id==currentAuditId}"
              @click="changeCompanyAudit(audit)"
              v-for="(audit, index) in existCompanyList"
              :key="index">
            <p>认证{{ index + 1 }}</p>
            <p>
              <i :class="{
                    'iconfont': true,
                    'icon-hirer': true,
                    'wh-12': true,
                    'b': audit.id!=currentAuditId}"></i>
              <span>{{ audit.company_name }}</span></p>
            <p>

              <i :class="{
                    'iconfont': true,
                    'icon-wait': audit.status==2,
                    'icon-attention': audit.status==3,
                    'icon-success': audit.status==9,
                    'wh-12': true }"
                 v-if="audit.id==currentAuditId">
              </i>
              <i :class="{
                      'iconfont': true,
                      'icon-wait': audit.status==2,
                      'b': audit.status==2,
                      'icon-attention': audit.status==3,
                      'r': audit.status==3,
                      'icon-success': audit.status==9,
                      'g': audit.status==9,
                      'wh-12': true
                    }"
                 v-else>
              </i>
              {{ audit.status_name }}
            </p>
          </div>

          <!--          暂不使用-->
          <el-button v-if="false" :class="{'addAuditBtn': true, 'checked': !isDisabled }" :disabled="!isDisabled"
                     size="mini"
                     @click="changeIsAdd">
            <i class="el-icon-plus"></i> 添加企业认证
          </el-button>

          <el-divider/>
        </el-row>

        <div class="tip" v-if="selectedCompanyAudit.status==3&&isShowFormAndInfo">
          <p><i class="iconfont icon-attention r wh-18"></i> {{ selectedCompanyAudit.last_verify_memo }}， 请重新上传资料！</p>
        </div>

        <div>
          <!--证件上传, 添加新认证 以及 审核失败 展现 -->
          <el-row v-if="!isDisabled">
            <div class="upload_header">
              <p class="upload_header_title"><span class="r">*</span> 营业执照副本扫描件</p>
              <el-upload
                  accept="image/jpeg,image/png"
                  :show-file-list="false"
                  :on-success="companyUploadSuccess"
                  :before-upload="beforeCompanyUpload"
                  :data="{ is_private: 1 }"
                  :action="postUrl"
                  :headers="headers"
              >

                <div :class="{ 'audit_image': true, 'error': !uploadMsg }"
                     v-lazy-container="{ selector: 'img', error: require('@/assets/banner/company_auth.jpg'), loading: require('@/assets/banner/loading.png') }">
                  <div v-if="company_pic==''">
                    <svg class="icon wh-32" aria-hidden="true">
                      <use v-if="uploadMsg" xlink:href="#icon-a-addto"></use>
                      <use v-if="!uploadMsg" xlink:href="#icon-a-notpass"></use>
                    </svg>
                    <p v-if="uploadMsg" style="margin-top: 20px">请上传营业执照副本扫描件</p>
                    <p v-if="uploadMsg">点击此区域上传证件图片</p>
                    <p v-if="!uploadMsg" class="r" style="margin-top: 20px"></p>
                    <p v-if="!uploadMsg" class="r">抱歉，上传发生了一些问题请重试！</p>
                  </div>
                  <img v-if="company_pic!=''" :data-src="company_pic" alt="">
                </div>
                <p class="r" v-if="notimgtext!=''" style="margin-top: 10px;font-size: 14px">{{ notimgtext }}</p>
              </el-upload>

              <div class="commpany_intro" v-if="company_pic==''">
                <p class="commpany_intro_title">
                  <svg class="icon wh-18" aria-hidden="true">
                    <use xlink:href="#icon-question-small"></use>
                  </svg>
                  &nbsp;&nbsp;对上传的营业执照图片有什么要求？
                </p>
                <p class="commpany_intro_memo">
                  1、上传图片必须是营业执照副本，图片要求必须为彩色、清晰的数码相机拍摄件或扫描件，涂改后无效。<br/>
                  2、图片上的企业名称必须与您企业信息中的企业名称完全一致。<br>
                  3、证件须在有效期之内，并且含最新年检通过信息（或为2014年新版营业执照）。<br>
                  4、图片必须清晰、完整、方向正确，不能带有水印、标记或者其他网站的logo。支持 jpg、png 图片，图片大小不超过4M。
                </p>
              </div>
            </div>
            <div style="width: 100%; float: left;margin-top: 20px">
              <el-button size="mini" style="margin-top: 20px; margin-bottom: 40px; width: 160px"
                         v-if="company_pic==''&&isShowFormAndInfo&&!isCanIgnore"
                         @click="goToBack"> 返回
              </el-button>

              <p class="btn_ignore" @click="nextStep" v-else-if="isCanIgnore">暂不认证，跳过</p>
            </div>
          </el-row>

          <el-row v-if="isDisabled&&isShowFormAndInfo">
            <p class="upload_header_title"><span class="r">*</span> 营业执照副本扫描件</p>
            <div class="audit_image" style="margin: 0 165px; margin-bottom: 20px"
                 v-lazy-container="{ selector: 'img', error: require('@/assets/banner/company_auth.jpg'), loading: require('@/assets/banner/loading.png') }">
              <div v-if="company_pic==''">
              </div>
              <img v-if="company_pic!=''" :data-src="company_pic" alt="">
            </div>

            <div class="userCompanyDiv">
              <el-form label-width="165px" label-position="right" size="small" :rules="userCompanyAuthRules">
                <el-form-item label="企业名称" prop="company_name">
                  <span>{{ selectedCompanyAudit.company_name }}</span>
                </el-form-item>
                <el-form-item label="统一社会信用代码" prop="company_number">
                  <span>{{ selectedCompanyAudit.company_number }}</span>
                </el-form-item>
                <el-form-item label="法定代表人" prop="company_legal_person">
                  <span>{{ selectedCompanyAudit.company_legal_person }}</span>
                </el-form-item>
                <el-form-item label="注册资本" prop="company_reg_capital">
                  <span>{{ selectedCompanyAudit.company_reg_capital }}</span>
                </el-form-item>
                <el-form-item label="住所" prop="company_address">
                  <span>{{ selectedCompanyAudit.company_address }}</span>
                </el-form-item>
                <el-form-item label="营业期限" prop="expires_type">
                  <span>{{ selectedCompanyAudit.company_expires }}</span>
                </el-form-item>
                <el-form-item label="经营范围" prop="company_business">
                  <span>{{ selectedCompanyAudit.company_business }}</span>
                </el-form-item>

                <el-form-item label="联系人姓名" prop="contact_mobile">
                  <span>{{ selectedCompanyAudit.contact_personal_name }}</span>
                </el-form-item>
                <el-form-item label="联系人手机号" prop="contact_mobile">
                  <span>{{ selectedCompanyAudit.contact_mobile }}</span>
                </el-form-item>
                <el-form-item label="联系人邮箱" prop="contact_email">
                  <span>{{ selectedCompanyAudit.contact_email }}</span>
                </el-form-item>
                <el-form-item label="法人身份证号码" prop="company_legal_person_idcard">
                  <span>{{ selectedCompanyAudit.company_legal_person_idcard }}</span>
                </el-form-item>
                <el-form-item label="企业邮箱" prop="company_email">
                  <span>{{ selectedCompanyAudit.company_email }}</span>
                </el-form-item>
                <el-form-item label="对公账号" prop="company_bank_num">
                  <span>{{ selectedCompanyAudit.company_bank_num }}</span>
                </el-form-item>
                <el-form-item label="开户行名称" prop="company_bank_name">
                  <span>{{ selectedCompanyAudit.company_bank_name }}</span>
                </el-form-item>

              </el-form>
              <div class="form_footer" v-if="isShowFormAndInfo">
                <el-button size="small" class="commpany_footer_btn" @click="goToBack(1)"> 返回</el-button>
              </div>
            </div>
          </el-row>
          <!--表单填写验证-->
          <el-row v-if="!isDisabled&&company_pic!=''">
            <div class="userCompanyDiv">
              <el-form ref="userCompanyAuthForm" :model="userCompanyAuthForm" :rules="userCompanyAuthRules"
                       label-width="165px" label-position="right" size="small">
                <el-form-item label="企业名称" prop="company_name">
                  <el-input v-model="userCompanyAuthForm.company_name" placeholder="请输入企业名称"></el-input>
                </el-form-item>
                <el-form-item label="统一社会信用代码" prop="company_number">
                  <el-input v-model="userCompanyAuthForm.company_number" placeholder="请输入统一社会信用代码"></el-input>
                </el-form-item>
                <el-form-item label="法定代表人" prop="company_legal_person">
                  <el-input v-model="userCompanyAuthForm.company_legal_person" placeholder="请输入法定代表人名称"></el-input>
                </el-form-item>
                <el-form-item label="注册资本" prop="company_reg_capital">
                  <el-input v-model="userCompanyAuthForm.company_reg_capital" placeholder="请输入注册金额"></el-input>
                </el-form-item>
                <el-form-item label="住所" prop="company_address">
                  <el-input v-model="userCompanyAuthForm.company_address" placeholder="请填写住所"
                            style="width: 520px"></el-input>
                </el-form-item>
                <el-form-item label="营业期限" prop="expires_type">
                  <el-radio-group v-model="userCompanyAuthForm.expires_type" @change="changeExpires"
                                  style="float: left; margin-left: unset">
                    <el-radio label="1"><span></span></el-radio>
                    <el-date-picker
                        style="width: 200px; margin-left: unset; margin-right: 10px"
                        :disabled="expiresTimeDisabled"
                        disableDate
                        value-format="yyyy-MM-dd"
                        v-model="userCompanyAuthForm.company_expires"
                        type="date"
                        placeholder="选择日期"
                    ></el-date-picker>
                    <el-radio label="2">长期</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="经营范围" prop="company_business">
                  <el-input v-model="userCompanyAuthForm.company_business" :autosize="{ minRows: 3 }"
                            placeholder="请输入经营范围" type="textarea" style="width: 520px; float: left"/>
                </el-form-item>

                <el-form-item label="行业分类" prop="company_cat_id">
                  <el-cascader
                      placeholder="请选择"
                      v-model="userCompanyAuthForm.company_cat_id"
                      :options="companyCats"
                      :props="{label: 'name',value: 'id'}"
                      @change="handleChange"/>
                </el-form-item>
                <el-form-item label="人数规模" prop="company_employees">
                  <el-select v-model="userCompanyAuthForm.company_employees" placeholder="请选择">
                    <el-option
                        v-for="(Eitem, Eindex) in commonConfig.company_employees"
                        :key="Eindex"
                        :label="Eitem"
                        :value="Eindex"/>
                  </el-select>
                </el-form-item>
                <el-form-item label="企业阶段" prop="company_finance_stage">
                  <el-select v-model="userCompanyAuthForm.company_finance_stage" placeholder="请选择">
                    <el-option
                        v-for="(Sitem, Sindex) in commonConfig.company_finance_stage"
                        :key="Sindex"
                        :label="Sitem"
                        :value="Sindex"/>
                  </el-select>
                </el-form-item>

                <el-form-item label="法人身份证号码" prop="company_legal_person_idcard">
                  <el-input v-model="userCompanyAuthForm.company_legal_person_idcard"
                            placeholder="请输入法人身份证号码"></el-input>
                </el-form-item>
                <el-form-item label="联系人姓名" prop="contact_personal_name">
                  <el-input v-model="userCompanyAuthForm.contact_personal_name" placeholder="请输入联系人姓名"></el-input>
                </el-form-item>
                <el-form-item label="联系人手机号" prop="contact_mobile">
                  <el-input v-model="userCompanyAuthForm.contact_mobile" placeholder="请输入联系人手机号"></el-input>
                </el-form-item>
                <el-form-item label="联系人邮箱" prop="contact_email">
                  <el-input v-model="userCompanyAuthForm.contact_email" placeholder="请输入联系人邮箱"></el-input>
                </el-form-item>
                <el-form-item label="企业简称" prop="company_short_name">
                  <el-input v-model="userCompanyAuthForm.company_short_name" placeholder="请输入企业简称"></el-input>
                </el-form-item>
                <el-form-item label="企业邮箱" prop="company_email">
                  <el-input v-model="userCompanyAuthForm.company_email" placeholder="请输入企业邮箱"></el-input>
                </el-form-item>
                <el-form-item label="对公账号" prop="company_bank_num">
                  <el-input v-model="userCompanyAuthForm.company_bank_num" placeholder="请输入企业对公账号"></el-input>
                </el-form-item>
                <el-form-item label="开户行名称" prop="company_bank_name">
                  <el-select v-model="userCompanyAuthForm.company_bank_name" filterable placeholder="请选择开户行名称">
                    <el-option
                        v-for="(item, index) in selectCompanyBanks"
                        :key="index"
                        :label="item"
                        :value="item"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="开户支行全称" prop="company_branch_bank">
                  <el-select
                      v-model="userCompanyAuthForm.company_branch_bank"
                      filterable
                      remote
                      reserve-keyword
                      placeholder="请输入支行全称"
                      :remote-method="remoteMethod"
                      :loading="selectLoading">
                    <el-option
                        v-for="item in selectCompanyBanks2"
                        :key="item.code"
                        :label="item.name"
                        :value="item.name"
                    />
                  </el-select>
                </el-form-item>

                <p class="r" style="font-size: 14px" v-if="formErrorText!=''"><i
                    class="iconfont icon-a-notpass wh-12"></i> {{ formErrorText }} </p>
              </el-form>
              <div class="form_footer" v-if="isShowFormAndInfo">
                <el-button type="primary" size="small" class="commpany_footer_btn" @click="toSubmit"> 提交认证</el-button>
                <el-button size="small" class="commpany_footer_btn" @click="toCancel"> 暂不提交</el-button>
              </div>
            </div>
          </el-row>
        </div>

      </div>

      <el-card shadow="never" class="main-content" v-if="isResult">
        <el-result icon="success" title="审核成功" subTitle="请根据提示进行操作" v-show="false">
          <template slot="extra">
            <el-button type="primary" size="mini">查看详情</el-button>
            <el-button size="mini">返回</el-button>
          </template>
        </el-result>

        <el-result icon="warning" title="等待审核" subTitle="请根据提示进行操作">
          <template slot="extra">
            <el-button type="primary" style="width: 160px" size="mini" @click="lookInfo">查看详情</el-button>
            <el-button style="width: 160px" size="medium" @click="goToBack(1)">返回</el-button>
          </template>
        </el-result>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import {getAuthCateList, getConfigList, getUserCompanyAuthList} from "@/api/auth";
import {createcompanyAuth} from "@/api/user";
import {checkedTaskAuth} from "@/api/task";
import {getToken} from "@/utils/auth";
import {getBankCodes} from "@/api/common";
// 引用组件时 必须要加 auditBackData 方法
export default {
  name: "userAuthIndex",
  components: {},
  props: {
    isCanIgnore: {// 是否可以跳过
      default: false
    },
    isOnlyAdd: { // 是否为新增
      default: false
    },
    isShowList: {// 是否展示选择已有认证列表
      default: true
    },
    isShowFormAndInfo: {// 此操作会隐藏 组件内部的 提交/返回 按钮 以及表单/详情展示
      default: true
    },
    task_id: {// 任务提交主体时 必须
      default: 0
    },
    audit_id: {
      default: 0
    }
  },
  data() {
    var checkCompanyType = (rule, value, callback) => {
      if (this.userCompanyAuthForm.company_expires == undefined && value == 1) {
        callback(new Error('请填写营业期限'));
      } else {
        callback()
      }
    }
    var validatorcompanyNumber = (rule, value, callback) => {
      var reg = /(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/;
      if (!reg.test(value)) {
        callback(new Error('填写的营业执照格式错误'));
      } else {
        callback();
      }
    }
    var validatorIdCard = (rule, value, callback) => {
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!reg.test(value)) {
        callback(new Error('填写的身份证号码格式错误'));
      } else {
        callback();
      }
    };
    var validatorMobile = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请填写手机号码'));
      } else {
        let reg = /^1([0-9])\d{9}$/;
        if (!reg.test(value)) {
          callback(new Error('请填写正确的手机号码'));
        }
      }
      callback();
    };
    var validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入邮箱'));
      } else {
        var pattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        if (!pattern.test(value)) {
          callback(new Error('您输入邮箱格式错误'));
        }
        callback();
      }
    };
    return {
      isAdd: false,
      isResult: false,

      selectLoading: false,
      selectCompanyBanks: [],
      selectCompanyBanks2: [],

      // 图片上传部分
      postUrl: '/api/auth/upload/company',
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
      isDisabled: false,// 是否可操作
      expiresTimeDisabled: true,

      // 表单和验证器
      userCompanyAuthForm: {
        expires_type: '1'
      },
      userCompanyAuthRules: {
        company_name: [
          {required: true, message: '请填写企业名称', trigger: 'blur'}
        ],
        company_number: [
          {required: true, message: '请填写统一社会信用代码', trigger: 'blur'}
        ],
        expires_type: [
          {required: true, message: '请选择营业期限', trigger: 'blur'},
          {validator: checkCompanyType, trigger: 'blur'},
        ],
        company_address: [
          {required: true, message: '请填写住所', trigger: 'blur'}
        ],
        company_expires: [
          {required: true, message: '请填写到期时间', trigger: 'blur'}
        ],
        company_business: [
          {required: true, message: '请填写营业范围', trigger: 'blur'}
        ],
        company_legal_person: [
          {required: true, message: '请填写法定代表人', trigger: 'blur'}
        ],
        company_reg_capital: [
          {required: true, message: '请填写注册金额', trigger: 'blur'}
        ],

        company_cat_id: [
          {required: true, message: '请选择行业分类', trigger: 'change'}
        ],
        company_employees: [
          {required: true, message: '请选择人数规模', trigger: 'change'}
        ],
        company_finance_stage: [
          {required: true, message: '请选择企业阶段', trigger: 'change'}
        ],
        company_short_name: [
          {required: false, message: '请填写企业简称', trigger: 'blur'}
        ],

        company_legal_person_idcard: [
          {required: true, message: '请填写法人身份证号码', trigger: 'change'},
          {validator: validatorIdCard, trigger: 'blur'}
        ],
        contact_personal_name: [
          {required: true, message: '请填写联系人姓名', trigger: 'blur'}
        ],
        contact_mobile: [
          {required: true, message: '请填写联系人手机号', trigger: 'blur'},
          {validator: validatorMobile, trigger: 'blur'}
        ],
        contact_email: [
          {required: true, message: '请填写联系人邮箱', trigger: 'blur'},
          {validator: validateEmail, trigger: 'blur'}
        ],
        company_bank_num: [
          {required: true, message: '请填写企业对公账号', trigger: 'blur'}
        ],
        company_bank_name: [
          {required: true, message: '请填写开户行名称', trigger: 'blur'}
        ],
        company_branch_bank: [
          {required: true, message: '请填写开户支行全称', trigger: 'blur'}
        ],
        company_email: [
          {required: true, message: '请填写企业邮箱', trigger: 'blur'},
          {validator: validateEmail, trigger: 'blur'}
        ],
      },

      currentAuditId: 0,
      selectedCompanyAudit: {},// 选中的认证

      // 已有认证的企业
      existCompanyList: [],

      company_pic: '',

      notimgtext: '',// 没上传提交

      uploadMsg: true,

      the_audit_id: 0,

      formErrorText: '',

      companyCats: [], //企业行业分类
      commonConfig: [], //配置项
    }
  },
  mounted() {
    this.isAdd = this.isOnlyAdd
    this.the_audit_id = this.audit_id
    if (!this.isOnlyAdd) {
      this.loadCompanyList()
    }

    this.getCatelist()
    this.getConfig()
    this.getCompanyBanks({'type': 1})
  },
  methods: {
    async loadCompanyList() {
      const res = await getUserCompanyAuthList()
      if (res.statusCode == 200) {
        if (res.data.length > 0) {
          this.existCompanyList = res.data
          // 默认取第一个
          if (this.the_audit_id > 0) {
            this.existCompanyList.map(element => {
              if (element.id == this.the_audit_id) {
                this.changeCompanyAudit(element)
              }
            })
          } else {
            this.changeCompanyAudit(this.existCompanyList[0])
          }
        }
      }
    },
    async addcompanyAuth(data) {
      const res = await createcompanyAuth(data);
      if (res.statusCode == 200) {
        var taskId = this.task_id;
        if (taskId > 0) {
          this.selectTaskAuth({'sn': taskId, 'auth_id': res.data.id})
        } else {
          this.isResult = true
        }
      } else {
        this.scrollToButtom()
        this.formErrorText = res.message
      }
    },
    async selectTaskAuth(data) {// 完成发布任务后 同步认证主体
      const res = await checkedTaskAuth(data);
      if (res.statusCode == 200) {
        this.$notify({
          type: 'success',
          title: '通知',
          message: '认证已提交....'
        });
        this.$redirectUrl.jumpToFrom('/task/result/' + this.task_id);
      } else {

        this.formErrorText = res.message
      }
    },
    async getCatelist() {
      const res = await getAuthCateList({level: 1})
      if (res.statusCode == 200) {
        this.companyCats = res.data
      }
    },
    async getConfig() {
      const res = await getConfigList()
      if (res.statusCode == 200) {
        this.commonConfig = res.data
      }
    },
    handleChange(val) {
      this.userCompanyAuthForm.company_cat_id = val[2]
    },
    changeIsAdd() {
      this.scrollToButtom()
      this.company_pic = ''
      this.isDisabled = false
      this.currentAuditId = 0
      // 引用组件时 必须要加 auditBackData 方法
      this.$emit('auditBackData', {id: 0});
    },
    changeCompanyAudit(audit) {
      this.currentAuditId = audit.id
      this.selectedCompanyAudit = audit
      // 引用组件时 必须要加 auditBackData 方法
      this.$emit('auditBackData', this.selectedCompanyAudit);
      if (audit.status == 3 && this.isShowFormAndInfo) {// 审核失败 表单清空
        this.company_pic = ''
        this.isDisabled = false
        this.userCompanyAuthForm.id = audit.id// 审核失败将失败的认证id赋值 以做修改
      } else {
        this.company_pic = audit.company_pic_id
        this.isDisabled = true
      }
    },
    companyUploadSuccess(res, file) {
      this.notimgtext = ''
      if (res.data == "" || res.statusCode !== 200) {
        this.company_pic = ''
        this.notimgtext = res.message
        this.uploadMsg = false
        return;
      } else {
        this.uploadMsg = true
      }
      let resultData = res.data[0]
      if (resultData.ocr.company_expires == '长期') {
        this.userCompanyAuthForm = resultData.ocr
        this.userCompanyAuthForm.expires_type = '2'
      } else {
        let company_expires = resultData.ocr.company_expires
        resultData.ocr.company_expires = company_expires.slice(0, 4) + '-' + company_expires.slice(4, 6) + '-' + company_expires.slice(6, 8);
        this.userCompanyAuthForm = resultData.ocr
        //this.expiresTimeDisabled = false
        this.userCompanyAuthForm.company_expires = resultData.ocr.company_expires
        this.userCompanyAuthForm.expires_type = '1'

      }
      this.userCompanyAuthForm.company_pic_id = resultData.fileid;
      this.company_pic = URL.createObjectURL(file.raw);
    },
    beforeCompanyUpload(file) {
      const isLt4M = file.size / 1024 / 1024 < 4;
      const isJPGORBMPORPNG = ['image/jpeg', 'image/png'].indexOf(file.type) >= 0;
      if (!isJPGORBMPORPNG) {
        this.$message.error('上传证件图必须是 JPG PNG格式!');
      }
      if (!isLt4M) {
        this.$message.error('上传证件图大小不能超过 4MB!');
      }
      return isJPGORBMPORPNG && isLt4M;
    },
    changeExpires(val) {
      if (val == '2') {
        this.expiresTimeDisabled = true
      } else {
        this.expiresTimeDisabled = false
      }
    },
    assignValues(backData) {
      // 返回得参数调取这里 可以在这里加一些样式效果
      this.userCompanyAuthForm = backData
    },
    lookInfo() {
      if (this.userCompanyAuthForm.status == 3) {
        this.the_audit_id = this.selectedCompanyAudit.id
      }
      this.loadCompanyList()
      this.isResult = false
    },
    goToBack(isLastPage = false) {
      if (isLastPage) {
        this.$redirectUrl.jumpToFrom('/home/page')
      } else {
        this.company_pic = ''
      }
    },
    toCancel() {
      this.company_pic = ''
      this.loadCompanyList()
    },
    toSubmit() {
      if (this.company_pic == '') {
        this.notimgtext = '请先上传营业执照！'
        return
      }
      this.$refs.userCompanyAuthForm.validate(valid => {
        if (valid) {
          let postData = JSON.parse(JSON.stringify(this.userCompanyAuthForm));
          if (postData.expires_type == 2) {
            postData.company_expires = '长期'
          }
          if (this.selectedCompanyAudit.status == 3) {
            postData.id = this.selectedCompanyAudit.id
          }
          this.addcompanyAuth(postData);
        } else {
          return false;
        }
      });
    },
    scrollToButtom() {
      let that = this
      this.$nextTick(() => {
        let theBox = that.$refs.mianBox // 获取对
        console.log(theBox.scrollHeight)
        if (theBox) {
          that.$refs.mianBox.scrollIntoView({
            // 平滑滚动
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest'
          });
        }

      })
    },
    nextStep() {
      this.$emit('auditBackData', {id: 0, toJump: true})
    },
    remoteMethod(query) {
      if (query !== '') {
        this.selectLoading = true;
        this.getCompanyBanks({'name':query, 'type': 2})
      } else {
        this.selectCompanyBanks2 = [];
      }
    },
    async getCompanyBanks(data) {
      const res = await getBankCodes(data)
      if (res.statusCode == 200) {
        this.selectLoading = false;
        if(data.type==1){
          this.selectCompanyBanks = res.data
        }else{
          this.selectCompanyBanks2 = res.data
        }
      } else {
        this.selectLoading = false;
      }
    }
  }
}
</script>

<style scoped>
.el-container {
  margin-bottom: 40px;
}

/deep/ .el-input {
  float: left;
  width: 360px;
}

/deep/ .el-form-item__content {
  text-align: left;
}

.el-cascader {
  float: left;
}

/deep/ .el-cascader .el-input {
  width: 520px;
}

/deep/ .el-button--primary {
  background-color: #00a2e6;
}

/deep/ .el-radio {
  float: left;
  margin-top: 10px;
  margin-right: unset;
}

/deep/ .el-form-item__label {
  font-weight: bold;
}

/deep/ .el-form-item__error {
  margin-top: unset;
}

/deep/ .el-textarea__inner {
  color: #575757 !important;
}

/deep/ .el-upload {
  float: left;
  margin-left: 5px;
  margin-bottom: 20px;
}

.el-main {
  text-align: center;
  overflow: unset;
  padding: unset;
}

.main-content {
  /*max-width: 800px;*/
  width: 100%;
  margin: 0 auto;
}

.main-content /deep/ .el-card__body {
  padding: 20px 30px;
}

.change-audit-btn {
  cursor: pointer;
}

.change-audit-btn.checked {
  cursor: pointer;
  border: 1px solid #00a2e6;
  background: #00a2e6;
  color: #fffdef;
}

.exist_title {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

/deep/ .exist_list {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  background-color: #f4f7fd;
  padding: 10px;
  height: 35px;
}

.exist_list p {
  box-sizing: border-box;
  float: left;
  padding-left: 10px;
}

.exist_list p:nth-child(1) {
  box-sizing: border-box;
  width: 100px;
  border-right: 1px solid #fffdef;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 20px;;
  text-overflow: ellipsis;
}

.exist_list p:nth-child(2) {
  box-sizing: border-box;
  width: 460px;
  padding-left: 20px;
  border-right: 1px solid #fffdef;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.exist_list span {
  margin-left: 10px;
}

.exist_list p:nth-child(3) {
  width: 140px;
  padding-left: 40px;
  text-align: left;
}

.exist_list.checked {
  background-color: #00a2e6;
  color: #F8F8F8;
}

.exist_list.checked:hover {
  color: #F8F8F8;
}

.exist_list:hover {
  color: #00a2e6;
}

.addAuditBtn {
  background-color: #00a2e6;
  width: 200px;
  margin-top: 20px;
  color: #F8F8F8;
  border: unset;
}

.addAuditBtn.checked {
  background-color: #b5b5b5;
  color: #F8F8F8;
}

.audit_image {
  width: 360px;
  height: 220px;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  background: url("~@/assets/banner/company_auth.jpg");
  background-size: 100% 100%;
}

.audit_image.error {
  border: 1px solid #E4007F;
}

.audit_image div {
  padding-top: 20%;
}

.audit_image img {
  width: 100%;
  height: 100%;
}

.upload_header_title {
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  width: 160px;
  float: left;
}

.commpany_intro {
  float: left;
}

.form_footer {
  margin-top: 40px;
}

.commpany_intro_title {
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.commpany_intro_memo {
  font-size: 12px;
  text-align: left;
  line-height: 22px;
}

.btn_ignore {
  font-size: 14px;
  color: #9CA3AF;
  cursor: pointer;
}

.commpany_footer_btn {
  width: 160px;
}

.tip {
  box-sizing: border-box;
  padding: 20px 16px;
  background-color: #f4f7fd;
  border-radius: 4px;
  border-left: 5px solid #E4007F;
  margin: 20px 0;
  text-align: left;
  font-size: 14px;
}

/deep/ .el-cascader .el-input {
  width: 360px !important;
}
</style>
